/* globals JSGlobals */
import { jsLoader } from './fileLoaders';

const accountId = 1096;

export const sourcepoint = () => {
  return new Promise((resolve) => {
    window._sp_queue = [];
    window._sp_ = {
      config: {
        accountId,
        baseEndpoint: `https://consent.${JSGlobals.domain}`,
        gdpr: {},
        events: {
          onConsentReady: () => resolve(null),
          onError: function (errorCode, errorObject, userReset) {
            console.log('errorCode: ' + errorCode);
            console.log(errorObject);
            console.log('userReset: ' + userReset);
            resolve(true);
          },
        },
      },
    };

    if (JSGlobals.mode === 'development') {
      window._sp_.config.propertyHref = 'https://www.newschainonline.com'
    }

    // const cookieSetting = document.getElementById('cookieSettings')
    // if (cookieSetting) {
    //   cookieSetting.addEventListener('click', () => {
    //     window._sp_.gdpr.loadPrivacyManagerModal(privacyId)
    //   })
    // }

    jsLoader([
      {
        src: 'https://cdn.privacy-mgmt.com/unified/wrapperMessagingWithoutDetection.js',
        crossOrigin: '',
      },
    ]);
  });
};
