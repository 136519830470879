export const checkConsent = (callback) => {
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === 'newschain-web-prod.brightsites.co.uk'
  ) {
    //quantcast not configured, so always return 'consent given' on localhost or preview domains...
    callback(true);
    return;
  }
  const apiCallback = (tcData, success) => {
    if (!success) {
      callback(false);
      return;
    }

    if (!['useractioncomplete', 'tcloaded'].includes(tcData.eventStatus)) {
      return;
    }

    if (!tcData.gdprApplies) {
      callback(true);
      return;
    }

    if (
      tcData.purpose?.consents?.['2'] && //Select basic ads
      tcData.purpose?.consents?.['3'] && //Create a personalised ads profile
      tcData.purpose?.consents?.['4']
    ) {
      //Select personalised ads
      callback(true);
      return;
    }

    callback(false);
  };

  window.__tcfapi('addEventListener', 2, apiCallback);
};
