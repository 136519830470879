/* globals jwplayer */

const jwplayerSetup = ({ containerId, options }) => {
    jwplayer(containerId).setup(options);
};

// eslint-disable-next-line no-unused-vars
export default (_consent) => {
    //consent value not actually used... but jwplayer set-up has to wait for consent to finish
    if (typeof jwplayer === 'function') {
      //jwplayer already loaded, therefore event has already fired; too late to set up a listener
      //get set-up parameters from data attributes on jwplayer script tag
      const scripts = document.querySelectorAll('script[data-jwplayer-uid]');
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i] && scripts[i].dataset.jwplayerUid) {
          const containerId = scripts[i].dataset.jwplayerUid;
          const options = JSON.parse(scripts[i].dataset.jwplayerData);
          jwplayerSetup({
            containerId,
            options,
          });
        }
      }
    } else {
      //jwplayer not loaded yet, set up a listener for the event that will be dispatched once it loads
      //get set-up parameters from event
      document.addEventListener('jwplayerLoaded', (event) => {
        const containerId = event.detail.uid;
        const options = event.detail.data;
        jwplayerSetup({
          containerId,
          options,
        });
      });
    }
  };