import { checkConsent } from './modules/cmp';
import JWPlayer from './modules/jwplayer';
import { sourcepoint } from '../../util/sourcepoint';

let adsInited = false;

/**
 * Display all ad slots on the page
 */
const displayAllAdSlots = () => {
  const adSlots = document.querySelectorAll('.adslot');
  adSlots.forEach((el) => {
    window.googletag.cmd.push(() => {
      window.googletag.display(el.id);
    });
  });
};

/**
 * Initialize Google Tag settings
 * @param {boolean} sourcepointConsent - User's consent status from Sourcepoint
 */
const initializeGoogleTag = (sourcepointConsent) => {
  window.googletag = window.googletag || { cmd: [] };
  const googletag = window.googletag;

  googletag.cmd.push(() => {
    // Determine if pageskin ads should be enabled based on screen width
    let fn_pageskin = 'false';
    if (!JSGlobals.disableInSkin) {
      if (
        (screen.width >= 375 && screen.width <= 480) ||
        screen.width >= 1260
      ) {
        fn_pageskin = 'true';
      }
    }
    googletag.pubads();

    // Set privacy settings based on user consent
    if (!sourcepointConsent) {
      googletag.pubads().setPrivacySettings({
        nonPersonalizedAds: true,
      });
    }

    // Enable single request mode and initialize services
    googletag.pubads().enableSingleRequest();
    googletag.enableServices();
  });

  displayAllAdSlots();
};

/**
 * Initialize ads based on user consent
 * @param {boolean} sourcepointConsent - User's consent status from Sourcepoint
 */
const initializeAds = (sourcepointConsent) => {
  checkConsent((consent) => {
    JWPlayer(consent);

    if (!adsInited) {
      adsInited = true;
      initializeGoogleTag(sourcepointConsent);
    }
  });
};

sourcepoint()
  .then(initializeAds)
  .catch((error) => {
    console.error('Error loading sourcepoint:', error);
  });
